const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');

const beer1 = new Image();
beer1.src = require('./beer.png');
const beer2 = new Image();
beer2.src = require('./beer-2.png');

const resize = () => {
	canvas.width = window.innerWidth * devicePixelRatio;
	canvas.height = window.innerHeight * devicePixelRatio;
	beer_size = Math.max(canvas.width, canvas.height) / 6;
	cx = canvas.width / 2;
	cy = canvas.height / 2;
	distance = Math.max(canvas.width, canvas.height) / 2.25;
}

const number_of_beers = 9;
let beer_size = null;
let cx = null;
let cy = null;
let distance = null;
const draw = () => {
	window.requestAnimationFrame(draw);
	ctx.clearRect(0, 0, canvas.width, canvas.height);

	const t_offset = Date.now() / 100000;
	const t_offset2 = Date.now() / 110000;

	for (let index = 0; index < number_of_beers; index++) {
		const p = index / number_of_beers;
		const r = p * Math.PI * 2 + t_offset;

		ctx.save();
		ctx.translate(
			Math.sin(r) * distance + cx,
			Math.cos(r) * distance + cy,
		);
		ctx.rotate(
			index % 2 == 0 ?
				Math.sin(p + t_offset2) * Math.PI * 2 :
				Math.cos(p + t_offset2) * Math.PI * 2
		);

		ctx.drawImage(
			index % 2 == 0 ? beer1 : beer2,
			-beer_size / 2,
			-beer_size / 2,
			beer_size,
			beer_size,
		);

		ctx.restore();
	}
}

window.addEventListener('DOMContentLoaded', () => {
	document.body.appendChild(canvas);
	window.addEventListener('resize', resize);
	resize();
	draw();
})